<template>
  <div>
    <p>test</p>
    <iframe class="stripe-iframe" src="https://buy.stripe.com/00gbJVf0QdxTcq4dQQ?locale=sv"></iframe>
  </div>
  
</template>

<script>
import axios from 'axios';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';


export default {
  name: 'StripePaymentLinkComponent',
  props: [],
  mixins: [ toasts ],
  components: {
    
  },
  computed: {

  },
  data() {
    return {

    };
  },
  async mounted() {

  },
  watch: {
    
  },
  beforeDestroy() {
    
  },
  methods: {

  }
};
</script>


<style lang="css" scoped>

.stripe-iframe {
  width: 100%;
}

</style>