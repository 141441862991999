<template>
  <div>
    <StripePaymentLinkComponent />
  </div>
</template>

<script>
import axios from 'axios';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import StripePaymentLinkComponent from './StripePaymentLinkComponent.vue';


export default {
  name: 'TestDonationPayment',
  props: [],
  mixins: [ toasts ],
  components: {
    StripePaymentLinkComponent
  },
  computed: {
    
    
  },
  data() {
    return {
      
    };
  },
  async mounted() {
    
  },
  watch: {
    
  },
  beforeDestroy() {
    
  },
  methods: {

  }
};
</script>


<style lang="css" scoped>
.amount {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  
  text-align: center;
}

.info {
  color: #1a1a1d;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

</style>